import "../style/league.css"
import React, { useEffect } from "react";

const LIOLeague = (props) => {
    const { weeklyPicks, picks,update, userStats, parlays, currentPot, setCurrentPot, currentPickEmPot, setCurrentPickEmPot, allPots, games, currentOfficialPot, picksixPicks, pickEmPicks} = props;
    // let weeklyPot = 0;
    
    // let seasonPot = (0.07 * allPots).toFixed(2)
    // setCurrentPot(weeklyPicks.length * 10)
    let weeklyPickEmArr = []
    let weeklyLIOArr = []

        const getPotAmounts = () => {
            
            if(pickEmPicks) {
                const isUnique = (item) => {
                    let weeklyPick = weeklyPicks.filter((pick) => pick.id === item.weeklyid && pick.active === true)
                    if (!weeklyPickEmArr.includes(item.weeklyid) && weeklyPick.length) {
                        weeklyPickEmArr.push(item.weeklyid)
                    }
                }
                pickEmPicks.forEach(isUnique) 
            }
            
            if(picks) {
                const isUnique = (item) => {
                    let weeklyPick2 = weeklyPicks.filter((pick) => pick.id === item.weeklyid && pick.active === true)
                    if (!weeklyLIOArr.includes(item.weeklyid) && weeklyPick2.length) {
                        weeklyLIOArr.push(item.weeklyid)
                    }
                }
                picks.forEach(isUnique) 
            }

            setCurrentPickEmPot(weeklyPickEmArr.length)
            setCurrentPot(weeklyLIOArr.length)
        }



    // if (currentPot) {
    //     weeklyPot = (0.93 * currentPot).toFixed(2)
    //     if (currentOfficialPot > 0) {
    //         seasonPot = (0.07 * (allPots)).toFixed(2)
    //     } else {
    //         seasonPot = (0.07 * (allPots + currentPot)).toFixed(2)
    //     }
    // }


    function showContainers(event) {
        let target = event.target.id
        let statsContainer = document.getElementById("seasonstats");
        let leaderboardContainer = document.getElementById("weeklyleaderboard");
        let picksContainer = document.getElementById("weeklypicks");
        // let p6Container = document.getElementById('weeklypicks6');
        let pickemContainer = document.getElementById('weeklypickem');

        if (target === "stats") {
            statsContainer.style.display = "initial";
            leaderboardContainer.style.display = "none";
            picksContainer.style.display = "none";    
            // p6Container.style.display = "none";
            pickemContainer.style.display = "none";
        }
    
        if (target === "leaderboard") {
            statsContainer.style.display = "none";
            leaderboardContainer.style.display = "initial";
            picksContainer.style.display = "none";  
            // p6Container.style.display = "none";
            pickemContainer.style.display = "none";  
    
        }
    
        if (target === "weekly-picks") {
            statsContainer.style.display = "none";
            leaderboardContainer.style.display = "none";
            picksContainer.style.display = "initial";
            // p6Container.style.display = "none"; 
            pickemContainer.style.display = "none";   
        }

        if (target === "weekly-p6-picks") {
            statsContainer.style.display = "none";
            leaderboardContainer.style.display = "none";
            picksContainer.style.display = "none";
            // p6Container.style.display = "initial"; 
            pickemContainer.style.display = "none";   
        }

        if (target === "weekly-pickem-picks") {
            statsContainer.style.display = "none";
            leaderboardContainer.style.display = "none";
            picksContainer.style.display = "none";
            // p6Container.style.display = "none"; 
            pickemContainer.style.display = "initial";   
        }
    }

    function collapsible(index) {
        const content = document.getElementById(`content-${index}`)
        if (content && content.style.display === "initial") {
            content.style.display = "none"
        } else if (content) {
            content.style.display = "initial"
        }
    }

    function collapsible2(index) {
        const content = document.getElementById(`picksixcontent-${index}`)
        if (content && content.style.display === "initial") {
            content.style.display = "none"
        } else if (content) {
            content.style.display = "initial"
        }
    }

    function collapsible3(index) {
        const content = document.getElementById(`seasonstats-${index}`)
        if (content && content.style.display === "initial") {
            content.style.display = "none"
        } else if (content) {
            content.style.display = "initial"
        }
    }

    function collapsible4(index) {
        const content = document.getElementById(`weeklyleaderboard-${index}`)
        if (content && content.style.display === "initial") {
            content.style.display = "none"
        } else if (content) {
            content.style.display = "initial"
        }
    }

    function collapsible5(index) {
        const content = document.getElementById(`pickemcontent-${index}`)
        if (content && content.style.display === "initial") {
            content.style.display = "none"
        } else if (content) {
            content.style.display = "initial"
        }
    }

    let btnContainer = document.getElementById("leagueButtons");

    if (btnContainer) {
        let btns = btnContainer.getElementsByClassName("buttons");
    
        for (let i = 0; i < btns.length; i++) {
            btns[i].addEventListener("click", function() {
                let current = document.getElementsByClassName("activeButton");
    
                if (current.length > 0) {
                    current[0].className = current[0].className.replace(" activeButton", "");
                }
    
                this.className += " activeButton";
            });
        }
    }

    function checkTime(gameidx) {
        let game = games.find(game => game.id === gameidx)
        const currentDate = new Date()
        const comparedDate = new Date(new Date(`${game.date}T${game.time}-0500`))

        if (currentDate > comparedDate) {
            return true
        } else {
            return false
        }
    }

    return (
        <div id="league-container">
            <div className="buttons-div" id="leagueButtons">
                <span className="buttons" id="stats" onClick={showContainers}>THIS SEASON</span>
                <span className="buttons" id="leaderboard" onClick={showContainers}>THIS WEEK</span>
                <span className="buttons" id="weekly-pickem-picks" onClick={showContainers}>PICK EM</span>
                <span className="buttons" id="weekly-picks" onClick={showContainers}>LOCK IT UP</span>
                {/* <span className="buttons" id="weekly-p6-picks" onClick={showContainers}>PICK 6</span> */}
            </div>
            <div id="seasonstats">
            <button type="button" className="collapsible" onClick={() => collapsible3(1)}>PICK EM SEASON LEADERBOARD</button>
                <div id="seasonstats-1">
                <div className="table">
                    <table>
                        <caption>PICK EM SEASON LEADERBOARD</caption>
                        <thead>
                            <tr>
                                <th>Username</th>
                                <th>Picks Record</th>
                                <th>Season Total Points</th>
                                <th>Wins</th>
                            </tr>
                        </thead>
                        <tbody>
                            { userStats.length ? userStats.map((userStat, idx) => {
                                return (<tr key={idx} className="userStat">
                                            <td>{userStat.username}</td>
                                            <td>{userStat.totalcorrectpickem ? userStat.totalcorrectpickem : 0}/{userStat.totalpickem ? userStat.totalpickem : 0}</td>
                                            <td>{userStat.totalcorrectpickem ? userStat.totalcorrectpickem : 0}</td>
                                            <td>{userStat.pickemwins ? userStat.pickemwins : 0}</td>
                                        </tr>)
                            }) : <tr><td colSpan={5}>No stats to display</td></tr>}
                        </tbody>
                    </table>
                </div>
                </div>
                <button type="button" className="collapsible" onClick={() => collapsible3(2)}>LOCK IT UP SEASON LEADERBOARD</button>
                <div id="seasonstats-2">
                    {/* <p id="seasonpot">This Season's Lock It Up Pot: ${seasonPot}</p> */}
                    <div className="table">
                        <table>
                            <caption>LOCK IT UP SEASON LEADERBOARD</caption>
                            <thead>
                                <tr>
                                    <th>Username</th>
                                    <th>Bet Record</th>
                                    <th>Lock Record</th>
                                    <th>Parlay Record</th>
                                    <th>Season Total Points</th>
                                    <th>Wins</th>
                                </tr>
                            </thead>
                            <tbody>
                                { userStats.length ? userStats.map((userStat, idx) => {
                                    return (<tr key={idx} className="userStat">
                                                <td>{userStat.username}</td>
                                                <td>{userStat.betscorrect}/{userStat.totalbets}</td>
                                                <td>{userStat.lockscorrect}/{userStat.totallocks}</td>
                                                <td>{userStat.parlayscorrect}/{userStat.totalparlays}</td>
                                                <td>{userStat.totalpoints}</td>
                                                <td>{userStat.wins}</td>
                                            </tr>)
                                }) : <tr><td colSpan={5}>No stats to display</td></tr>}
                            </tbody>
                        </table>
                </div>
                </div>

            </div>
            <div id="weeklyleaderboard">
                <button type="button" className="collapsible" onClick={() => {collapsible4(1); getPotAmounts()}}>PICK EM WEEKLY LEADERBOARD</button>
                <div id="weeklyleaderboard-1">
                <p id="weeklypot">This Week's Pick Em Pot: ${currentPickEmPot*10}</p>
                <div className="table">
                    <table>
                        <caption>PICK EM WEEKLY LEADERBOARD</caption>
                        <thead>
                            <tr>
                                <th>Username</th>
                                <th>Pick Record</th>
                                <th>Total Points</th>
                            </tr>
                        </thead>
                        <tbody>
                            { weeklyPicks.length ? weeklyPicks.map((weeklyPick, idx) => {
                                let pickempick = pickEmPicks.filter((pick) => weeklyPick.id === pick.weeklyid)
                                if (pickempick.length) {
                                return (<tr key={idx} className="weeklyPick">
                                            <td>{weeklyPick.username}</td>
                                            <td>{weeklyPick.totalcorrectpickem ? weeklyPick.totalcorrectpickem : 0}/{weeklyPick.totalpickem ? weeklyPick.totalpickem : 0}</td>
                                            <td>{weeklyPick.totalpickempoints ? weeklyPick.totalpickempoints : 0}</td>
                                        </tr>)
                            } else {
                                return null
                            }}) : <tr><td colSpan={5}>No stats to display</td></tr>}
                        </tbody>
                    </table>
                </div>
                </div>
                <button type="button" className="collapsible" onClick={() => {collapsible4(2); getPotAmounts()}}>LOCK IT UP WEEKLY LEADERBOARD</button>
                <div id="weeklyleaderboard-2">
                    <p id="weeklypot">This Week's Pot: ${currentPot*10}</p>
                    <div className="table">
                        <table>
                            <caption>LOCK IT UP WEEKLY LEADERBOARD</caption>
                            <thead>
                                <tr>
                                    <th>Username</th>
                                    <th>Bet Record</th>
                                    <th>Lock Record</th>
                                    <th>Parlay Record</th>
                                    <th>Total Points</th>
                                </tr>
                            </thead>
                            <tbody>
                                { weeklyPicks.length ? weeklyPicks.map((weeklyPick, idx) => {
                                    let liopick = picks.filter((pick) => weeklyPick.id === pick.weeklyid)
                                    if (liopick.length) {
                                    return (<tr key={idx} className="weeklyPick">
                                                <td>{weeklyPick.username}</td>
                                                <td>{weeklyPick.betscorrect}/{weeklyPick.totalbets}</td>
                                                <td>{weeklyPick.lockscorrect}/{weeklyPick.totallocks}</td>
                                                <td>{weeklyPick.parlayscorrect}/{weeklyPick.totalparlays}</td>
                                                <td>{weeklyPick.totalpoints}</td>
                                            </tr>)
                                    } else {
                                        return null
                                    }}) : <tr><td colSpan={5}>No stats to display</td></tr>}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
            <div id="weeklypicks">
                { weeklyPicks.length ? weeklyPicks.map((weeklyPick, idx) => {
                    let parlay1total = 0;

                    function updateParlayTotal(weeklyid) {
                        const parlay1 = parlays.filter(parlay => parlay.parlaynumber === 1 && parlay.weeklyid === weeklyid)
                        let onepointswon = 0;
                        let onepointslost = 0;
                        let oneparlayshit = 0;
                        let onetbd = false
                
                        if (parlay1.length === 2) {
                            onepointswon = 4
                            onepointslost = -2
                        } else if (parlay1.length === 3) {
                            onepointswon = 10
                            onepointslost = -3
                        } else if (parlay1.length === 4) {
                            onepointswon = 20
                            onepointslost = -4
                        } else if (parlay1.length === 5) {
                            onepointswon = 30
                            onepointslost = -5
                        } else if (parlay1.length === 6) {
                            onepointswon = 60
                            onepointslost = -6
                        }
                
                        parlay1.forEach((parlay) => {
                            if (!parlay.statsupdated) {
                                onetbd = true
                                return
                            } else if (parlay.result === "HIT") {
                                oneparlayshit++
                            }
                        })
                
                        if (oneparlayshit === parlay1.length && !onetbd) {
                            parlay1total = onepointswon
                        } else if (!onetbd) {
                            parlay1total = onepointslost
                        } else {
                            parlay1total = 0;
                        }                        
                    }

                    updateParlayTotal(weeklyPick.id)

                    return (
                    <React.Fragment key={idx}>
                    { picks.filter(pick => pick.weeklyid === weeklyPick.id).length || parlays.filter(parlay => parlay.weeklyid === weeklyPick.id && parlay.parlaynumber == 1).length ? <>
                        <button type="button" className="collapsible" onClick={() => collapsible(idx)}>{weeklyPick.username}'s Picks --- Total Points: {weeklyPick.totalpoints}</button>
                        <div key={idx} className="weeklyPick-content" id={`content-${idx}`}>
                            { picks.filter(pick => pick.weeklyid === weeklyPick.id).length ? <>
                                <div className="table">
                                    <table>
                                        <caption>{weeklyPick.username}'s Picks</caption>
                                        <thead>
                                            <tr>
                                                <th>Picks</th>
                                                <th>Lock</th>
                                                <th>Point Potential</th>
                                                <th>Results</th>
                                                <th>Points Awarded</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            { picks ? picks.map((pick, index) => {
                                                if (pick.weeklyid === weeklyPick.id  && checkTime(pick.gameid)) {
                                                    return (
                                                        <tr key={index}>
                                                            <td key={'pick' + index}>{pick.text}</td>
                                                            <td key={'lock' + index}>{pick.lock ? "LOCK" : null}</td>
                                                            <td key={'points' + index}>{pick.worth}</td>
                                                            <td key={'outcome' + index}>{pick.outcometext}</td>
                                                            <td key={'pointsawarded' + index}>{pick.outcome === "tbd" ? "tbd" : pick.pointsawarded}</td>
                                                        </tr>
                                                    )
                                                } else {
                                                    return null
                                                }
                                            }): <tr><td>{weeklyPick.username} has not made any picks yet.</td></tr>}
                                        </tbody>
                                    </table>
                                </div>
                            </> : null }
                            { parlays.filter(parlay => parlay.weeklyid === weeklyPick.id && parlay.parlaynumber == 1).length ? <>
                                <div className="table">
                                    <table>
                                        <caption>{weeklyPick.username}'s Parlay</caption>
                                        <thead>
                                            <tr>
                                                <th>Pick</th>
                                                <th>Result</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                                { parlays ? parlays.map((parlay, index) => {
                                                        if (parlay.weeklyid === weeklyPick.id && parlay.parlaynumber == 1 && checkTime(parlay.gameid)) {
                                                            return (
                                                                <tr key={index}>
                                                                    <td>{parlay.text}</td>
                                                                    <td>{parlay.result}</td>
                                                                </tr>
                                                            )
                                                        }
                                                    }): <tr><td>{weeklyPick.username} has not made a parlay yet.</td></tr>}
                                                <tr>
                                                    <th>Points Awarded</th>
                                                    <td>{parlay1total}</td>
                                                </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </> : null}
                        </div>
                    </> : null}
                    </React.Fragment>)
                }) : <div>No picks to display</div> }
            </div>
            <div id="weeklypicks6">
                { weeklyPicks.length ? weeklyPicks.map((weeklyPick, idx) => {
                    let picksixoutcome = 'tbd'
                    picksixPicks.map((pick) => {
                        if (weeklyPick.id === pick.weeklyid && pick.result === "MISS") {picksixoutcome = "MISS"}
                    })
                    return (
                    <React.Fragment key={idx}>
                    { picksixPicks.filter(pick => pick.weeklyid === weeklyPick.id).length ? <>
                        <button type="button" className="collapsible" onClick={() => collapsible2(idx)}>{weeklyPick.username}'s Parlay --- {picksixoutcome} </button>
                        <div key={idx} className="weeklyPick-content" id={`picksixcontent-${idx}`}>
                                <div className="table">
                                    <table>
                                        <caption>{weeklyPick.username}'s Picks</caption>
                                        <thead>
                                            <tr>
                                                <th>Picks</th>
                                                <th>Result</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            { picksixPicks ? picksixPicks.map((pick, index) => {
                                                if (pick.weeklyid === weeklyPick.id  && checkTime(pick.gameid)) {
                                                    return (
                                                        <tr key={index}>
                                                            <td key={'pick' + index}>{pick.text}</td>
                                                            <td key={'outcome' + index}>{pick.result}</td>
                                                        </tr>
                                                    )
                                                } else {
                                                    return null
                                                }
                                            }): <tr><td>{weeklyPick.username} has not made any picks yet.</td></tr>}
                                        </tbody>
                                    </table>
                                </div>
                        </div>
                    </> : null }
                    </React.Fragment>)
                }) : <div>No picks to display</div> }
            </div>
            <div id="weeklypickem">
                { weeklyPicks.length ? weeklyPicks.map((weeklyPick, idx) => {
                    return (
                    <React.Fragment key={idx}>
                    { pickEmPicks.filter(pick => pick.weeklyid === weeklyPick.id).length ? <>
                        <button type="button" className="collapsible" onClick={() => collapsible5(idx)}>{weeklyPick.username}'s Picks --- Total Points: {weeklyPick.totalpickempoints}</button>
                        <div key={idx} className="weeklyPick-content" id={`pickemcontent-${idx}`}>
                                <div className="table">
                                    <table>
                                        <caption>{weeklyPick.username}'s Picks</caption>
                                        <thead>
                                            <tr>
                                                <th>Picks</th>
                                                <th>Results</th>
                                                <th>Points Awarded</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            { pickEmPicks ? pickEmPicks.map((pick, index) => {
                                                if (pick.weeklyid === weeklyPick.id  && checkTime(pick.gameid)) {
                                                    return (
                                                        <tr key={index}>
                                                            <td key={'pick' + index}>{pick.text}</td>
                                                            <td key={'outcome' + index}>{pick.outcometext.toUpperCase()}</td>
                                                            <td key={'points' + index}>{pick.pointsawarded}</td>
                                                        </tr>
                                                    )
                                                } else {
                                                    return null
                                                }
                                            }): <tr><td>{weeklyPick.username} has not made any picks yet.</td></tr>}
                                        </tbody>
                                    </table>
                                </div>
                        </div>
                    </> : null }
                    </React.Fragment>)
                }) : <div>No picks to display</div> }
            </div>
        </div>
    )
}

export default LIOLeague;