import '../style/pickem.css'
import convertTime from 'convert-time';
import { showAlert } from "./Alert";

const API_URL = 'https://floating-stream-77094.herokuapp.com/api'

const PickEm = (props) => {
    const { token, setAlertMessage, update, setUpdate, sortedGames, myPicks, setPicks, setMyPicks, user, weeklyPicks } = props;

    const postPickEm = async (pickemData) => {
        const { pickemPicks } = pickemData
        let alert = ""

        for (let i = 0; i < pickemPicks.length; i++) {
            await fetch(`${API_URL}/pickem/addPickEmPick`, {
                method: "POST",
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`
                },
                body: JSON.stringify({
                    gameid: pickemPicks[i].gameId,
                    type: pickemPicks[i].type,
                    bet: pickemPicks[i].bet,
                    text: pickemPicks[i].text,
                })
            }).then(response => response.json())
            .then(result => {
                if (result.name) {
                    alert = result.message
                }
            })
            .catch(console.error)
        }

        if (!alert) {
            alert = "You have made your pickem!!"
        }

        document.getElementById("pickem-confirmation-container").style.display = "none"
        setAlertMessage(alert)
        showAlert();
        confirmEditPickEm();
        setUpdate(!update)
    }

    function submitPickEm(event) {
        let pickEmArr = [];
        let disabledGames = 0



        sortedGames.map((game, idx) => {
            let chalkteam = "";
            let dogteam = ""

            if (game.favoredteam === 'home') {
                chalkteam = game.hometeam;
                dogteam = game.awayteam;
            }

            if (game.favoredteam === 'away') {
                chalkteam = game.awayteam;
                dogteam = game.hometeam;
            }

            if (game.dog || game.chalk) {
                let pickemPick = {
                    gameId: game.id,
                }

                if (document.getElementById(`pickem-dog-${idx}`).disabled || document.getElementById(`pickem-chalk-${idx}`).disabled) {
                    disabledGames++
                    console.log('disabled: ', disabledGames)
                }

                if (document.getElementById(`pickem-dog-${idx}`).checked) {
                    pickemPick.type = "moneyline"
                    pickemPick.bet = "dog"
                    pickemPick.text = dogteam.toUpperCase()
                } else if (document.getElementById(`pickem-chalk-${idx}`).checked) {
                    pickemPick.type = "moneyline"
                    pickemPick.bet = "chalk"
                    pickemPick.text = chalkteam.toUpperCase()
                }

                if (pickemPick.type) {
                    pickEmArr.push(pickemPick)
                }
            }  
        })

        let pickemData = {
            pickemPicks: pickEmArr
        }

        const numberOfGames = sortedGames.length

        if (pickEmArr.length + disabledGames < numberOfGames) {
            setAlertMessage("You must make a pick for EVERY game")
            showAlert()
        } else if (event.target.id === "confirm-pickem-button"){
            document.getElementById("pickem-confirmation-container").style.display = "initial"
            confirmPickEm(pickemData)
        } else if (event.target.id === "submit-pickem-button") {
            postPickEm(pickemData)
        }
    }

    function confirmPickEm(data) {
        const { pickemPicks } = data
        let pickemConfirmationContainer = document.getElementById("confirm-pickem");
        let pickemConfirmationHTML = "";

        for (let i = 0; i < pickemPicks.length; i++) {
            let pickem = pickemPicks[i];

            let pickemHTML = 
            `<tr>
                <td>${i+1}</td>
                <td>${pickem.text}</td>
            </tr>`

            pickemConfirmationHTML += pickemHTML
        }

        pickemConfirmationContainer.innerHTML += pickemConfirmationHTML;
    }

    function confirmEditPickEm() {
        let pickemConfirmationContainer = document.getElementById("confirm-pickem");
        pickemConfirmationContainer.innerHTML = ""
        document.getElementById("pickem-confirmation-container").style.display = "none"
    }

    function checkTime(date, time) {
        const currentDate = new Date()
        const comparedDate = new Date(new Date(`${date}T${time}-0500`))

        if (currentDate > comparedDate) {
            return true
        } else {
            return false
        }
    }

    function onlyOne(checkboxId, pick) {
        let checkbox = document.getElementById(checkboxId);
        let checkboxes = document.getElementsByName(pick)
        checkboxes.forEach((item) => {
            if (item !== checkbox) item.checked = false
        })
    }

    function showContainers(event) {
        let target = event.target.id
        let rulesContainer = document.getElementById("pickem-rules");
        let picksContainer = document.getElementById('pickem-games');
        let rulesButton = document.getElementById('pickemrules')
        let picksButton = document.getElementById('pickem')


        if (target === "pickem") {
            picksContainer.style.display = "initial";
            rulesContainer.style.display = "none";
            picksButton.style.backgroundColor = "#0076F0";
            rulesButton.style.backgroundColor = "black"
        }

        if (target === "pickemrules") {
            picksContainer.style.display = "none";
            rulesContainer.style.display = "initial";
            picksButton.style.backgroundColor = "black";
            rulesButton.style.backgroundColor = "#0076F0"
        }
    }

    return (
        <div>
            <div className="buttons-div">
                <span className="buttons" id="pickemrules" onClick={showContainers}>RULES</span>
                <span className="buttons" id="pickem" onClick={showContainers}>PICK EM</span>
            </div>
            <div id="pickem-rules">
            <div id="howto">
                    <h2><span style={{color: "#0076F0"}}>--- </span>HOW TO PLAY<span style={{color: "#0076F0"}}> ---</span></h2>
                    <ul>
                        <li>Venmo @WaldoSportsCo $10 to play</li>
                        <li>Pick a winner for every game listed</li>
                        <li>The player with the most correct picks wins!!</li>
                        <li>If there is a tie, players will split the money</li>
                    </ul>
                </div>
            </div>
            <div id="pickem-games">
                <form id="pickem-form">
                    { sortedGames ? sortedGames.map((game, idx) => {
                        return (
                        <div key={idx} className='pickemgame'>
                            <div className='info'>
                                {game.date && game.time ? <p className="date">{new Date(`${game.date}T${game.time}`).toDateString()} at {convertTime(game.time)} CT</p> : null}
                                <p className="level" id={`level-${idx}`}>{game.level} {game.primetime ? <span>PRIMETIME</span> : null}</p>
                            </div>
                            <div className='gamestuff'>
                                <div className="spread">
                                    { game.awayteam && game.hometeam && game.favoredteam === 'home' ? <>
                                        { game.dog ? <><input className="pick-checkbox" type='checkbox' id={`pickem-dog-${idx}`} name={`pickem-spread-${idx}`} onChange={() => onlyOne(`pickem-dog-${idx}`, `pickem-spread-${idx}`)} disabled={checkTime(game.date, game.time) === true ? true : false }></input>
                                        <label htmlFor={`pickem-dog-${idx}`} className="pick-checkbox-label" id={`label-pickem-dog-${idx}`}>{game.awayteam.toUpperCase()}</label></> : null}
                                        { game.chalk ? <><input className="pick-checkbox" type='checkbox' id={`pickem-chalk-${idx}`} name={`pickem-spread-${idx}`} onChange={() => onlyOne(`pickem-chalk-${idx}`, `pickem-spread-${idx}`)} disabled={checkTime(game.date, game.time) === true ? true : false }></input>
                                        <label htmlFor={`pickem-chalk-${idx}`} className="pick-checkbox-label" id={`label-pickem-chalk-${idx}`}>{game.hometeam.toUpperCase()}</label></> : null}
                                        </> : null}
                                    { game.awayteam && game.hometeam && game.favoredteam === 'away' ? <>
                                        { game.chalk ? <><input className="pick-checkbox" type='checkbox' id={`pickem-chalk-${idx}`} name={`pickem-spread-${idx}`} onChange={() => onlyOne(`pickem-chalk-${idx}`, `pickem-spread-${idx}`)} disabled={checkTime(game.date, game.time) === true ? true : false }></input>
                                        <label htmlFor={`pickem-chalk-${idx}`} className="pick-checkbox-label" id={`label-pickem-chalk-${idx}`}>{game.awayteam.toUpperCase()}</label></> : null}
                                        { game.dog ? <><input className="pick-checkbox" type='checkbox' id={`pickem-dog-${idx}`} name={`pickem-spread-${idx}`} onChange={() => onlyOne(`pickem-dog-${idx}`, `pickem-spread-${idx}`)} disabled={checkTime(game.date, game.time) === true ? true : false }></input>
                                        <label htmlFor={`pickem-dog-${idx}`}className="pick-checkbox-label" id={`label-pickem-dog-${idx}`}>{game.hometeam.toUpperCase()}</label></> : null}
                                        </> : null}
                                </div>
                            </div>
                        </div>
                        )
                    }) : <div>No games to display</div>}
                    <br />
                    <div className="submit-button">
                        <input type='button' onClick={submitPickEm} id="confirm-pickem-button" value='CONFIRM PICKS'></input>
                    </div>
                </form>
            </div>
            <div className="pickem-table" id="pickem-confirmation-container">
                <table>
                    <caption>CONFIRM PICKS</caption>
                    <thead>
                        <tr>
                            <th></th>
                            <th>PICK</th>
                        </tr>
                    </thead>
                    <tbody id="confirm-pickem"></tbody>
                    <tfoot>
                        <tr>
                            <td><input type="button" value="BACK TO PICKS" onClick={confirmEditPickEm}></input></td>
                            <td><input type="button" value="SUBMIT PICKS" id="submit-pickem-button" onClick={(e) => submitPickEm(e)}></input></td>
                        </tr>
                    </tfoot>
                </table>
            </div>
        </div>
    )
}

export default PickEm